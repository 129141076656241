import Vue from 'vue'
import VueRouter from 'vue-router'
import usersModel from '@/models/users.model'
import store from '@/store'

Vue.use(VueRouter)

/** 분리되어있는 routes 파일들 **/
import UsersRoutes from './student/users.routes'

import LectureRoutes from './student/lecture.routes'
import CodeReviewRoutes from './student/codereview.routes'
import quizRouters from "./student/quiz.routers";

import AdminRoutes from './admin/admin.routes'
import TeacherRoutes from './teacher/teacher.routes'


const hostName = window.location.hostname
window.hostName = hostName;
window.theme = "default";

if(window.hostName === 'teacher.ezitlms.co.kr') {
  window.theme = "teacher";
}
else if (window.hostName === 'admin.ezitlms.co.kr') {
  window.theme = "admin";
}
else if(window.hostName === 'localhost') {
  window.theme = 'admin'
}


let routes = []

// 학생용일 경우
if(window.theme === 'default') {
  routes.push(
      { path: '/', name: 'MainDashboard', component: () => import(/* webpackChunkName: "student.dashboard" */ '../views/Student/DashBoard') },
      { path: '/ai-chat', name: 'ChatGPT', component: () => import(/* webpackChunkName:"student.chatgpt" */ '../views/Student/ChatGPT'), meta: {useLayout:false} },
      { path: '/xr-edu', name: 'xrEdu', component: () => import(/* webpackChunkName:"student.xredu" */ '../views/Student/Xr.vue')},
      ...UsersRoutes,
      ...LectureRoutes,
      ...CodeReviewRoutes,
      ...quizRouters,
  )
}
// 관리자일 경우
else if (window.theme === 'admin') {
  routes.push(
    ...AdminRoutes,
    ...UsersRoutes,
    ...LectureRoutes,
    ...CodeReviewRoutes,
  )
}
// 선생님일 경우
else if (window.theme === 'teacher') {
  routes.push(
      { path: '/', name: 'teacherMainDashboard', component: () => import(/* webpackChunkName: "teacher.dashboard" */ '../views/Teacher/DashBoard') },
      ...TeacherRoutes,
      ...UsersRoutes,
      ...LectureRoutes,
      ...CodeReviewRoutes,
  )
}

routes.push({ path: '*', name: 'Error404NotFound', component: () => import(/* webpackChunkName: "error.404" */ '../views/errors/Error404'), meta: {requiredLogin:false, useLayout:false} });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})


router.beforeEach((to,from, next) => {
  const isRequiredLogin = !(typeof to.meta !== 'undefined' && typeof to.meta.requiredLogin !== 'undefined' && to.meta.requiredLogin === false);
  const useLayout = !(typeof to.meta !== 'undefined' && typeof to.meta.useLayout !== 'undefined' && to.meta.useLayout === false);
  const theme = window.theme

  store.commit('layout/setLayout', useLayout);
  store.commit('layout/setMainTitle', '')
  store.commit('layout/setTheme', theme)

  if(isRequiredLogin && ! usersModel.isLogin()) {
    next('/sign-in')
  }
  else {
    next();
  }
})

export default router
