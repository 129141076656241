<template>
  <component :is="theme" />
</template>

<script>
import StudentLayout from '@/views/Student/ThemeLayout.vue'
import TeacherLayout from '@/views/Teacher/ThemeLayout.vue'
import AdminLayout from '@/views/Admin/ThemeLayout.vue'
import usersModel from '@/models/users.model'

export default {
  components: {
    'default' : StudentLayout,
    'admin' : AdminLayout,
    'teacher' : TeacherLayout
  },
  computed: {
    theme () {
      return this.$store.getters["layout/theme"]
    }
  },
  sockets: {
    duplicateLogin () {
      console.log("중복로그인 수신");
      usersModel.signOut();
      alert('중복 로그인이 감지되었습니다. 다시 로그인 해주세요.');
      this.$router.push('/sign-in'); // 로그인 페이지로 이동
    }
  }
}
</script>

<style lang="scss">
html {
  -webkit-overflow-scrolling: touch;
  overflow:hidden;
  touch-action:none;
}
html, body {
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: contain;
  scroll-behavior: unset !important;
  scroll-snap-type: none !important;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
body {
  background:#fff;
}
.v-application {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
#app {
  height: calc(var(--vh, 1vh) * 100);
  overflow-x:auto;
  overflow-y:hidden;
  .container {
    width:1200px;
    margin:0 auto;
    min-width:1200px;
  }
  .full-container {
    max-width:1600px;
    min-width:1200px;
    margin:0 auto;
  }
  .v-application--wrap {
    min-height: calc(var(--vh, 1vh) * 100) !important; // 덮어씌우기
    overflow:hidden;
    box-sizing: border-box;
    min-width:1600px;
    position:relative;
    z-index:2;
  }
  &.no-layout {
    .v-application--wrap {
      min-width:0;
    }
  }
  &.admin-theme {
    .v-main__wrap {
      height:100vh;
    }
  }
}
.v-main {
  overflow:hidden;
  .v-main__wrap {
    height:100vh;
    overflow-y:auto;
    box-sizing: border-box;
    width:100%;
    padding:10px 0 0 0;
    display:flex;
    flex-direction: column;
    .contents-wrap {
      width:100%;
      height:calc(100% - 55px);
      overflow-y:scroll;
    }
    .contents-footer {
      width:100%;
      height:55px;
      background-color: #f5f5f5;
      .inner{
        width:100%;
        max-width:1600px;
        margin:0 auto;
        padding-top:10px;
      }
    }
  }
  &.other{
    width:100% !important;
    //overflow: hidden;
    .v-main__wrap {
      padding:0;
      height:100vh;
      .container {
        width: 100% !important;
        max-width: 100% !important;
        margin:auto !important;
        box-sizing: border-box;
      }
      .row {
        height:100vh;
        align-items: center;
        justify-content: center;
      }
      .contents-wrap {
        height:calc(var(--vh, 1vh) * 100);
      }
      .contents-footer {
        display: none;
        height:0;
      }
    }
  }
}
.swal2-container {
  z-index:20060 !important;
}
.full-container,
.container.full-container {
  width:100%;
  max-width:1600px;
  margin: 0 auto;
  .left-box {
    flex-basis: auto;
    position:relative;
    flex-shrink: 1;
    flex-grow: 1;
    min-width:0;
  }
  .right-box {
    flex-shrink: 0;
    width:370px;
    margin-left:30px;
    .inner-box {
      width:100%;
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      .--home-box {
        width:100%;
      }
    }
  }
}

.chatbot-container {
  position: absolute;
  bottom: 40px;
  right: 40px;
  height: 45px;
  width: 100px;
  animation: up-down 4.5s infinite ease-in-out;
  cursor: pointer;
  z-index: 10000;
  &.other{
    display: none;
  }
  #chatbot {
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 150px;
    height: 85px;
    border: 10px solid #3d3e45;
    border-radius: 5rem;
    background-color:#fff;
    transform: scale(0.5);
  }

  #chatbot-corner {
    margin: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 50px;
    left: 5px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 25px solid #3d3e45;
    transform: rotate(137deg) scale(0.4);
  }

  #antenna {
    margin: auto;
    position: absolute;
    bottom: -60px;
    left: 50%;
    right: 0;
    height: 20px;
    width: 10px;
    background-color: #3d3e45;
    animation: antenna-appear 4.5s infinite ease-in-out;
    transform: translate(0, 0) scale(0.5);

    #beam {
      position: absolute;
      top: 0px;
      left: -5px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #3d3e45;
      animation: beam-appear 4.5s infinite ease-in-out;
    }

    #beam-pulsar {
      position: absolute;
      top: 0px;
      left: -5px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #3d3e45;
      animation: beam-pulsar-appear 4.5s infinite ease-in-out;
    }
  }

  .dot {
    height: 12.5px;
    width: 12.5px;
    margin: auto;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: -65px;
    background-color: #3d3e45;
    border-radius: 50%;
    animation: pulse-outer 4.5s infinite ease-in-out;

    &:nth-child(2) {
      left: 0;
      animation: pulse-inner 4.5s infinite ease-in-out;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      left: 65px;
      animation: pulse-outer 4.5s infinite ease-in-out;
      animation-delay: 0.4s;
    }
  }
}

@keyframes pulse-inner {
  0% {
    transform: scale(1);
  }

  7.5% {
    transform: scale(1.5);
  }

  15% {
    transform: scale(1);
  }

  22.5% {
    transform: scale(1.5);
  }

  30% {
    transform: scale(1);
  }

  37.5% {
    transform: scale(1.5);
  }

  45% {
    top: 0;
    transform: scale(1);
    height: 17.5px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: rotate(-370deg);
  }

  50% {
    top: 22.5px;
    height: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    transform: rotate(10deg);
  }

  55% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(10deg);
  }

  65% {
    transform: rotate(-10deg);
  }

  65% {
    transform: rotate(0deg);
  }

  85% {
    top: 22.5px;
    height: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    transform: rotate(0deg);
  }

  92.5% {
    top: 22.5px;
    height: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    height: 17.5px;
    border-radius: 50%;
    transform: rotate(-360deg);
  }
}

@keyframes pulse-outer {
  0% {
    transform: scale(1);
  }

  7.5% {
    transform: scale(1.5);
  }

  15% {
    transform: scale(1);
  }

  22.5% {
    transform: scale(1.5);
  }

  30% {
    transform: scale(1);
  }

  37.5% {
    transform: scale(1.5);
  }

  45% {
    transform: scale(1);
    height: 17.5px;
  }

  55% {
    tranform: scale(1);
    height: 5px;
  }

  60% {
    height: 17.5px;
  }

  75% {
    height: 17.5px;
  }

  80% {
    transform: scale(1);
    height: 5px;
  }

  85% {
    height: 17.5px;
  }

  100% {
    height: 17.5px;
  }
}

@keyframes antenna-appear {
  0% {
    visibility: hidden;
    top: -100px;
    height: 0;
  }

  50% {
    visibility: hidden;
    top: -100px;
    height: 0;
  }

  55% {
    visibility: visible;
    top: -125px;
    height: 20px;
  }

  95% {
    visibility: visible;
    top: -125px;
    height: 20px;
  }

  100% {
    top: -100px;
    height: 0;
  }
}

@keyframes beam-appear {
  0% {
    visibility: hidden;
    top: -12.5px;
    height: 0;
  }

  50% {
    visibility: hidden;
    top: -12.5px;
    height: 0;
  }

  55% {
    visibility: visible;
    top: -12.5px;
    height: 20px;
    width: 20px;
  }

  100% {
    visibility: visible;
    top: -12.5px;
    height: 20px;
    width: 20px;
  }
}

@keyframes beam-pulsar-appear {
  0% {
    visibility: hidden;
    top: -12.5px;
    height: 0;
  }

  50% {
    visibility: hidden;
    top: -12.5px;
    height: 0;
  }

  55% {
    visibility: visible;
    top: -12.5px;
    left: -5px;
    height: 20px;
    width: 20px;
    opacity: 1;
  }

  65% {
    top: -25px;
    left: -15px;
    height: 40px;
    width: 40px;
    opacity: 0;
    visibility: visible;
  }

  74% {
    visibility: hidden;
    opacity: 0;
  }

  75% {
    visibility: visible;
    top: -12.5px;
    left: -5px;
    height: 20px;
    width: 20px;
    opacity: 1;
  }

  85% {
    top: -25px;
    left: -15px;
    height: 40px;
    width: 40px;
    opacity: 0;
    visibility: visible;
  }

  94% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes up-down {
  0% {
    transform: translate(0);
  }

  12.5% {
    transform: translate(0, 2%);
  }

  25% {
    transform: translate(0);
  }

  37.5% {
    transform: translate(0, 2%);
  }

  50% {
    transform: translate(0);
  }

  62.5% {
    transform: translate(0, 2%);
  }

  75% {
    transform: translate(0);
  }

  87.5% {
    transform: translate(0, 2%);
  }

  100% {
    transform: translate(0);
  }
}

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index:10;
}



</style>
