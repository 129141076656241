<template>
  <w-popup title="내정보 변경" width="500" @close="$emit('close')">
    <v-form @submit.prevent="saveInfo" class="ms-auto me-auto mt-2">
        <v-text-field hide-details class="mb-4" outlined label="이메일 주소" :value="formData.emailAddress" disabled />

        <v-text-field hide-details class="mb-2" outlined label="이름" v-model.trim="formData.nickname" />

        <a-form-group>
          <a-controls>
            <v-text-field
                :disabled="!formData.isPhoneChange||phoneAuth.certificated||phoneAuth.isSend"
                hide-details class="mb-2"
                outlined
                ref="phoneNumber"
                label="휴대폰번호"
                @keypress="preventNonNumericInput"
                @blur="onBlurPhone"
                v-model.trim="formData.phone"
            />
          </a-controls>

          <v-btn class="mt-2" x-large v-if="!formData.isPhoneChange" @click="changePhoneNumber">휴대폰 변경</v-btn>
          <template v-if="formData.isPhoneChange">
            <v-btn
                :disabled="phoneAuth.isSend||phoneAuth.certificated"
                x-large class="mt-2"
                @click="sendPhoneCode"
                :loading="phoneAuth.isLoading">인증번호 발송</v-btn>
          </template>
        </a-form-group>

      <a-form-group v-if="phoneAuth.isSend||phoneAuth.certificated">
        <a-controls>
          <v-text-field
              outlined
              type="text"
              v-model.trim="formData.phoneAuthCode"
              label="인증번호"
              required
              :disabled="phoneAuth.certificated"
              hide-details
          />
        </a-controls>
        <v-btn
            :disabled="phoneAuth.certificated"
            x-large
            class="mt-2"
            @click="checkPhoneAuth"
            :loading="phoneAuth.isLoading">인증 {{phoneAuth.certificated?'완료':'확인'}}</v-btn>
      </a-form-group>
      <div v-if="phoneAuth.isSend||phoneAuth.certificated" class="mb-4"><span v-if="phoneAuth.isSend">인증 유효시간:{{phoneAuth.remainTime}}</span></div>



      <div class="agree-checkbox">
          <label class="--wrap">
            <input type="checkbox" v-model="formData.agreeMarketing" true-value="Y" false-value="N">
            <span class="--text">(선택) 마케팅 목정의 개인정보 수집 및 이용에 동의</span>
          </label>
          <v-btn icon @click="dialog=true; openAgreement('marketing')"><v-icon>mdi-plus-circle</v-icon></v-btn>
        </div>
        <div class="agree-checkbox">
          <label class="--wrap">
            <input type="checkbox" v-model="formData.agreeReceive" true-value="Y" false-value="N">
            <span class="--text">(선택) 광고성 정보 수신에 동의</span>
          </label>
          <v-btn icon @click="dialog=true; openAgreement('receive')"><v-icon>mdi-plus-circle</v-icon></v-btn>
        </div>

        <div class="text-right mb-2">
          <v-btn variant="outlined" small link to="/my/withdraw" @click="$emit('close')">회원탈퇴</v-btn>
        </div>

        <v-btn type="submit" color="black" dark block>변경하기</v-btn>
    </v-form>
    <template>
      <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2 d-sr-only" v-html="ui.agreementTitle"></v-card-title>
            <v-card-text style="min-height:300px;">
              <marketing-view class="pt-10" v-if="ui.agreementOpened&&(ui.agreementType==='marketing' || ui.agreementType==='receive')" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="onDialogSubmit">
                동의
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </w-popup>
</template>

<script>

import jwt from "@/plugins/jwt";
import MarketingView from "@/views/Student/agreement/MarketingView";
import usersModel from '@/models/users.model'
export default {
  name:'MyInfoModify',
  components: {MarketingView},
  data () {
    return {
      dialog: false,
      ui: {
        agreementType: '',
        agreementTitle: '',
      },
      formData: {
        agreeReceive: 'N',
        agreeMarketing: 'N',
        nickname: '',
        phone: '',
        phoneAuthCode: '',
        emailAddress:'',
        isPhoneChange: false
      },
      phoneAuth: {
        certificated: false,
        isSend: false,
        remainTime: 0,
        remainTimeInterval : null,
        code: '',
        isLoading:false
      }
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    changePhoneNumber () {
      this.formData.isPhoneChange = true
      this.$nextTick(() => {
        this.$refs.phoneNumber.$refs.input.focus();
      })

    },
    resetPhoneAuth () {
      if(this.phoneAuth.remainTimeInterval !== null) {
        clearInterval(this.phoneAuth.remainTimeInterval);
        this.phoneAuth.remainTimeInterval = null;
      }
      this.phoneAuth.remainTime = 0
      this.phoneAuth.isSend = false;
      this.phoneAuth.certificated = false;
    },
    checkPhoneAuth () {
      if(this.formData.phoneAuthCode.toString() === this.phoneAuth.code.toString()) {
        this.resetPhoneAuth();
        this.phoneAuth.certificated = true
      }
      else {
        this.$swal('인증실패','인증번호가 올바르지 않습니다.', 'error');
      }
    },
    // 휴대폰 인증번호 발송
    sendPhoneCode () {
      this.phoneAuth.certificated = false;

      if(this.phoneAuth.isLoading) {
        return;
      }
      if(this.formData.phone.length === 0) {
        this.$swal('입력오류','올바른 형식의 휴대폰 번호가 아닙니다.', 'error');
        return;
      }

      this.phoneAuth.isLoading = true
      jwt.axios.post('/v1/sens/auth/phone', {
        phone: this.formData.phone
      }).then((res) => {
        this.formData.phoneAuthCode = ''
        this.phoneAuth.code = res.data.result.authCode;
        this.phoneAuth.isSend = true
        this.phoneAuth.remainTime = 180
        this.phoneAuth.remainTimeInterval = setInterval(()=> {
          if(this.phoneAuth.remainTime <= 1) {
            this.resetPhoneAuth()
          }
          this.phoneAuth.remainTime--;
        }, 1000)
      }).finally(() => {
        this.phoneAuth.isLoading = false
      })
    },
    onBlurPhone() {
      if(this.formData.phone === '') return;

      const transNum = this.validatePhoneNumber(this.formData.phone);
      if(transNum === '') {
        this.$swal('입력오류','올바른 형식의 휴대폰 번호가 아닙니다.','error');
        this.formData.phone = ''
      }
      else {
        this.formData.phone = transNum
      }
    },
    getInfo () {
      jwt.axios.get('/v1/users').then(res => {
        for(let key in res.data) {
          if(typeof this.formData[key] !== 'undefined') {
            this.formData[key] = res.data[key]
          }
        }

      })
    },
    saveInfo () {
      const data = this.formData

      if(this.formData.isPhoneChange) {
        if(! this.phoneAuth.certificated) {
          this.$swal('입력 오류','휴대폰 번호 인증이 완료되지 않았습니다.');
          return;
        }

        if(this.formData.phone.length === '') {
          this.$swal('입력 오류','휴대폰 번호 인증이 완료되지 않았습니다.');
          return;
        }
      }

      jwt.axios
          .put('/v1/users',data)
        .then(() => {
          this.$swal('정보수정완료','내 정보가 저장되었습니다.','success')
          usersModel.getMyInfo();
          this.getInfo()
        })
    },
    onDialogSubmit(){
      if (this.ui.agreementType === 'marketing') {
        this.formData.agreeMarketing = 'Y';
      }
      else if (this.ui.agreementType === 'receive') {
        this.formData.agreeReceive = 'Y';
      }
      this.dialog = false
    },
    openAgreement(type) {
      this.ui.agreementOpened = true
      this.ui.agreementType = type
      if (type === 'marketing') {
        this.ui.agreementTitle = '마케팅 목정의 수신정보 동의'
      }
      else if (type === 'receive') {
        this.ui.agreementTitle = '광고성 수신정보 동의'
      }
    },
  },
  metaInfo() {
    return {
      title: `내 정보 수정`,
      meta: [
        {vmid:'og:title', property:'og:title', content: `내 정보 수정`}
      ]
    }
  }
}
</script>