<template>
    <div>

        <v-navigation-drawer app clipped v-model="drawer"
                             :mini-variant.sync="miniVariant"
                             permanent class="nav overflow-y-auto d-flex flex-column" v-scroll.self="onScroll">
          <div class="d-flex justify-space-around pl-3 align-center logo-box">
            <v-img :width="34" :height="38" aspect-ratio="1/1" contain
                   :src="require('@/assets/images/common/logo.png')"
                   class="d-inline-block"></v-img>
            <v-list-item-title class="logo-title"><strong>Codelab</strong><br>K digital Smart LMS</v-list-item-title>
            <v-btn v-if="!mini" icon @click.stop="menuToggle" class="btn-close" >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>

          <div class="user-box d-flex justify-space-between align-center" :class="{minify:mini}" >
            <template v-if="isLogin">
              <div class="user-photo-wrap d-flex flex-nowrap align-center justify-center">
                <div class="img-box-container">
                  <div class="img-box">
                    <v-avatar width="60" height="60">
                      <v-img :src="loginUser.photo?loginUser.photo:'http://placehold.it/200x200'" style="background-color:#fff; margin:8px; border:1px solid rgba(255,255,255,0.75)"/>
                    </v-avatar>
                    <v-btn class="btn-upload-photo" icon @click="$refs.uploadInput.click()"><v-icon>mdi-camera</v-icon></v-btn>
                    <input type="file" hidden class="upload-input" ref="uploadInput" accept="image/*" @change="onFileChange">
                  </div>
                </div>
                <div class="d-flex flex-column align-center ml-2">
                  <strong>{{loginUser.nickname}}</strong>
                  <div class="info-box-container d-flex">
                    <template>
                      <div class="text-xs-center">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                color="gray"
                                outlined
                                small
                                inline
                                height="30"
                                elevation="0"
                                style="z-index: 1000;"
                                class="mt-1"
                                v-on="on">
                              <v-icon small left>mdi-account</v-icon>
                              내정보
                            </v-btn>
                          </template>
                          <v-list dense class="pt-0 pb-0">
                            <v-list-item link>
                              <v-list-item-title small @click="ui.infoChange=true">정보수정</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link>
                              <v-list-item-title small @click="ui.passwordChange=true">비밀번호 변경</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link>
                              <v-list-item-title small @click="ui.infoSocial=true">소셜 로그인 설정</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link>
                              <v-list-item-title small @click="signOut">로그아웃</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </div>
                </div>

              </div>
            </template>
          </div>

          <v-list class="menu-list">

            <v-list-item link to="/" title="홈">
              <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>홈</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- v-list-item @click="goClassum('')" target="_blank" title="클래스룸">
              <v-list-item-icon><v-icon>mdi-alpha-c-circle</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>클래스룸</v-list-item-title>
              </v-list-item-content>
            </v-list-item -->

            <v-list-item link to="/coding-editor" title="코드씽크" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon>mdi-file-code-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드씽크</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/coding-test" title="코드위드미" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon> mdi-comment-text-multiple</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드위드미</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/code-review" title="코드 리뷰" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon>mdi-xml</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드 리뷰</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/quiz" title="코딩 퀴즈" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon>mdi-quora</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>퀴즈코딩</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link title="XR 교육장" to="/xr-edu" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon>mdi-alpha-x-box</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>XR 교육장</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link title="웨비나룸" href="https://www.spatial.io/s/EZENs-Smart-Webinar-64868a22b3cadd5fd0012247?share=1754770392879237591" target="_blank" v-if="loginUser.isIT">
              <v-list-item-icon><v-icon>mdi-rotate-3d</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>웨비나룸</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-spacer />

            <v-list-item dense link :ripple="false" title="훈련생 유의사항" href="https://ezen-academy.gitbook.io/lms_precautions/" target="_blank" class="other-btn">
              <v-list-item-icon><v-icon>mdi-alert-circle-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>훈련생 유의사항</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense link :ripple="false" title="기초 IT 용어집" href="https://ezen-academy.gitbook.io/ezen-it-book-web-development/" target="_blank" class="other-btn">
              <v-list-item-icon><v-icon>mdi-book-open-page-variant-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>기초 IT 용어집</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense link :ripple="false" title="Smart LMS 사용자 가이드" href="https://docs.codelabit.co.kr/codelab-smart-lms-1/" target="_blank" class="other-btn">
              <v-list-item-icon><v-icon>mdi-book-search-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="letter-spacing: -0.05em;">스마트 LMS 사용자 가이드</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- v-list-item dense link :ripple="false" title="기업프로젝트 협력신청" href="https://docs.google.com/forms/d/1jc0bq7uOI-mpTcflje2MdHbgEipDPzzQaZIrFaL-gX4/viewform?edit_requested=true" target="_blank" class="other-btn">
              <v-list-item-icon><v-icon>mdi-briefcase-arrow-left-right-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>기업프로젝트 협력신청</v-list-item-title>
              </v-list-item-content>
            </v-list-item -->
          </v-list>

        </v-navigation-drawer>

        <my-password-change v-if="ui.passwordChange" @close="ui.passwordChange=false" />
        <my-info-modify v-if="ui.infoChange" @close="ui.infoChange=false" />
        <my-info-social v-if="ui.infoSocial" @close="ui.infoSocial=false"/>

    </div>
</template>

<style>
.v-navigation-drawer__content {overflow-y: hidden !important;}
</style>

<style lang="scss" scoped>
  .--main-title {white-space:nowrap;}
  .nav {background-color: #36bc9b; color:#fff;}
  .logo-title { color:#1e1e1e; font-size:15px; margin-left:15px; line-height:1.2; font-weight: 600;}
  .logo-title > strong { font-size:25px; font-weight: 800; letter-spacing:6px; color:#1e1e1e; }
  .btn-close {color:#fff !important; background-color:#1e1e1e; border:1px solid #fff !important; transform: scale(0.75); margin-right:5px; }

  .menu-list { min-height: calc(100% - 200px); height:100%; box-sizing: border-box; display: flex; flex-direction: column; overflow-y: auto;padding-bottom:220px;}
  .menu-list .v-list-item { font-size:12px !important; font-weight:700; flex:initial;}
  .menu-list .v-icon{ font-size:22px; display:block; margin:0 auto; padding-left:10px; }
  .nav .v-list-item {
    position:relative;
    min-height:36px;
    &.v-list-item--active {
      opacity:1;
      &:before{
        background-color:#f5f5f5;
        opacity: 0.5;
      }
    }
    &.other-btn {
      //background-color:#003a2f;
      border-radius: 50px;
      margin:0 10px;
      color:#fff;
      border:1px solid #fff;
      transition: all 0.2s ease;
      min-height:30px;
      .v-list-item__title{
        font-size:14px;
        position:relative;
        z-index:3;
        letter-spacing: -0.05em;
      }
      .v-list-item__icon {
        margin-right:15px;
      }
      .v-icon {
        color:#fff;
      }
      & + .other-btn {
        margin-top:5px;
      }
      &:focus {
        &:before{
          margin:0;
        }
      }
    }
  }
  .v-list-item--link:before { border-radius: 50px; margin:0 10px; background-color: #fff;}
  .v-list-item--link.v-list-item--active:before {opacity: 1}
  .v-list-item__icon {margin-right:5px; position:relative; z-index:2;}
  .v-list-item__title { position:relative; z-index: 2;}
  .v-application--is-ltr .v-list-item__icon:first-child{margin-right:20px;}
  .logo-box {flex-shrink: 1; border-bottom: 1px solid rgba(255,255,255,0.5); padding:29px 0; background-color:#fff;}
  .img-box-container {
    width:80px;
    height:60px;
    text-align: center;
  }
  .user-info-container,
  .user-photo-wrap  {width: 100%;}
  .user-box {
    -webkit-transition:width 0.5s, padding-left 0.5s;
    transition:width 0.5s, padding-left 0.5s;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    height:100px;
    position:relative;
    color:#1e1e1e;
    .img-box {
      position:relative;
      width:80px;
      height:60px;
      > img {
        position:relative;
        z-index: 1;
      }
      > button {
        position:absolute;
        right:0;
        bottom:-5px;
        background: #1e1e1e;
        color: #fff;
        transform: scale(0.75);
      }
      .img-box {
        > span {
          margin:0;
        }
      }
    }

    .info-box {
      width:100%;
      strong {
        font-size:18px;
      }
    }

  }
  .v-menu__content {
    .v-list {
      .v-list-item {
        min-height:2rem;
        font-size:14px;
      }
    }
  }
  .minify {
    .nav .v-list-item.other-btn {
      padding:0 6px;
    }
    .user-photo-wrap {
      transform: scale(0.65);
      margin-left:30px;
    }
    .menu-list .v-icon { padding-left:0;}
  }
</style>

<script>
import usersModel from "@/models/users.model";
import MyPasswordChange from "@/views/Student/users/MyPasswordChange";
import MyInfoModify from "@/views/Student/users/MyInfoModify";
import MyInfoSocial from "@/views/Student/users/MyInfoSocial";
import jwt from "@/plugins/jwt";

export default {
  components: { MyInfoModify, MyPasswordChange,  MyInfoSocial},
  props: {
    mini: {
      type: Boolean,
      default : false,
      required: false
    }
  },
  mounted () {
    this.miniVariant = this.mini
  },
  data () {
    return {
      dialog: false,
      drawer: true,
      scrollInvoked:0,
      userPhoto: null,
      isChanging: false,
      ui: {
        passwordChange: false,
        infoChange: false,
        infoWithdraw: false,
        infoSocial:false,
        chatGPT: false
      },
      miniVariant: false,
      myLectureList: {
        result: [],
        totalCount: 0,
        page: 1,
        pageRows: 5
      }
    }
  },
  watch: {
    miniVariant () {
      if(this.isChanging) return;
      this.isChanging = true;
      this.$emit('menuToggle', this.miniVariant)
      this.isChanging  =false
    },
    mini () {
      this.miniVariant = this.mini
    }
  },
  methods: {
    menuToggle () {
      this.miniVariant= !this.miniVariant
    },
    signOut() {
      usersModel.signOutProcess()
    },
    /**
     * 사용자 사진 변경을 클릭하고, 이미지를 선택했을때 처리
     */
    async onFileChange () {
      const files = this.$refs.uploadInput.files;

      // 선택된 파일이 없을 경우 나가기
      if(files.length ===0) return;

      // 넘겨줄 데이타를 정리하기
      const formData = new FormData();
      formData.append('userfile', this.$refs.uploadInput.files[0])

      // 일단 이미지 업로드 처리
      let photoUrl = '';
      await jwt.axios.post('/uploads/users', formData, {
        headers: {
          "Content-Type": "multipart/form-vod-data"
        }
      }).then((res) => {
        photoUrl = res.data[0]

        // 파일 선택 초기화
        this.$refs.uploadInput.value = "";
      })

      // 만약 이미지가 업로드 되지 않았다면 이후 과정은 실행하지 않음
      if(photoUrl.length === 0) {
        return;
      }

      // 업로드된 이미지의 경로로 현재 사용자의 이미지 교체하기
      await jwt.axios.patch('/v1/users/photo', {
        photo: photoUrl
      }).then(() => {
        usersModel.getMyInfo()
      })

    },
    onScroll () {
      this.scrollInvoked++
    },
  },


}
</script>
