import vue from 'vue';
import store from '@/store';
import jwt from '@/plugins/jwt';

const exportObject = {};

/**
 * ---------------------------------------------------------------------
 * 사용자라 로그인 중인지 체크
 * ---------------------------------------------------------------------
 */
exportObject.isLogin = () => {
    const accessToken = localStorage.getItem('accessToken');
    return !!(accessToken && accessToken !== 'undefined');
};

/**
 * ---------------------------------------------------------------------
 * 로그인이 완료되었을때 이후 store 처리
 * ---------------------------------------------------------------------
 */
exportObject.loginAfterProcess = async (res) => {
    if (
        typeof res.data.accessToken !== 'undefined' &&
        typeof res.data.userData !== 'undefined'
    ) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);

        store.commit('authorize/setLogin', true);

        await exportObject.getMyInfo(store.getters["authorize/userInfo"].id);
    } else {
        vue.swal('Error', '사용자 로그인에 실패하였습니다', 'error');
        store.commit('authorize/setLogin', false);
        store.commit('authorize/setUserInfo', null);
        throw new Error('사용자 로그인에 실패');
    }
}

/**
 * ---------------------------------------------------------------------
 * REST 서버와 통신하여 사용자 로그인 처리
 * ---------------------------------------------------------------------
 */
exportObject.loginProcess = async (payload) => {
    return await jwt.axios
        .post('/v1/users/authorize', {
            loginId: payload.loginId,
            loginPass: payload.loginPass,
        })
        .then(async (res) => {
            await exportObject.loginAfterProcess(res)
        })
};

/**
 * ---------------------------------------------------------------------
 * 내 정보를 갖고온 후, store에 저장
 * ---------------------------------------------------------------------
 */
exportObject.getMyInfo = async () => {
    return await jwt.axios.get('/v1/users').then(res => {

        localStorage.setItem('userInfo', JSON.stringify(res.data));
        let sessionID = localStorage.getItem('sessionID');

        store.commit('authorize/setUserInfo', res.data)

        vue.prototype.$socket.emit('signIn', {
            nickname: res.data.nickname,
            userId: res.data.id,
            photo: res.data.photo,
            sessionID
        })
    })
}

/**
 * ---------------------------------------------------------------------
 * 로그아웃 선택창 띄우기
 * ---------------------------------------------------------------------
 */
exportObject.signOutProcess = () => {
    vue
        .swal({
            title: '로그아웃',
            text: '로그아웃 하시겠습니까?',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '로그아웃',
            cancelButtonText: '취소',
        })
        .then((result) => {
            if (result.isConfirmed) {
                exportObject.signOut();
                location.reload();
            }
        });
};

/**
 * ---------------------------------------------------------------------
 * 실제 사용자 로그아웃 처리
 * ---------------------------------------------------------------------
 */
exportObject.signOut = (noRefresh) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    store.commit('authorize/setLogin', false);
    store.commit('authorize/setUserInfo', null);

    if(!(typeof noRefresh !== 'undefined' && noRefresh === true)) {
        location.reload();
    }

};

/**
 * ---------------------------------------------------------------------
 * 사용자의 아이콘 이미지 변경
 * ---------------------------------------------------------------------
 */


export default exportObject;