<template>
  <div>
    <h1>개인정보처리방침</h1>
    <br>
    <h4>"{{name}}"는 훈련생의 개인정보를 소중히 생각하며 가장 적극적이고 효과적으로 처리하기 위해 최선을 다해 노력하고 있습니다.</h4><br>
    <ul class="list hypen">
      <li>"{{name}}"(이하 "{{name2}}")은 고객의 개인정보를 소중하게 생각하며, '개인정보보호법' 등 관련법규를 준수하기 위해 '{{name2}} 개인정보처리방침'을 제정하고 이를 준수하고 있습니다.</li>
      <li>"{{name2}}" 개인정보처리방침은 법률의 제·개정, 정부의 정책 변경, "{{name2}}" 내부방침의 변경에 따라 바뀔 수 있습니다.</li>
      <li>"{{name2}}"은 개인정보처리방침을 개정하는 경우 "{{name2}}" 웹사이트({{siteUrl}}) 공지사항(또는 개별공지)를 통하여 공지할 것입니다.</li>
      <li>훈련생 여러분께서는 "{{name2}}" 홈페이지({{siteUrl}}) 방문 시 수시로 확인해 주시길 바랍니다.</li>
      <li>"{{name2}}"의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</li>
    </ul>
    <br /><br />
    <ol class="list">
      <li><span class="W60 d-inline-block">제 1 조</span> 총칙</li>
      <li><span class="W60 d-inline-block">제 2 조</span> 수집하는 개인정보의 항목 및 수집 방법</li>
      <li><span class="W60 d-inline-block">제 3 조</span> 개인정보의 처리목적</li>
      <li><span class="W60 d-inline-block">제 4 조</span> 개인정보의 수집 및 이용목적</li>
      <li><span class="W60 d-inline-block">제 5 조</span> 개인정보의 처리 및 보유기간</li>
      <li><span class="W60 d-inline-block">제 6 조</span> 개인정보의 제3자 제공</li>
      <li><span class="W60 d-inline-block">제 7 조</span> 정보주체의 권리, 의무 및 행사방법</li>
      <li><span class="W60 d-inline-block">제 8 조</span> 처리하는 개인정보 항목</li>
      <li><span class="W60 d-inline-block">제 9 조</span> 개인정보의 파기</li>
      <li><span class="W60 d-inline-block">제 10 조</span> 개인정보의 안전성 확보조치</li>
      <li><span class="W60 d-inline-block">제 11 조</span> 개인정보 보호책임자</li>
      <li><span class="W60 d-inline-block">제 12 조</span> 권익침해 구제방법</li>
      <li><span class="W60 d-inline-block">제 13 조</span> 개인정보 처리방침 변경</li>
    </ol>

    <h5 class="list-title">제 1 조 - 총칙</h5>
    <ol class="list number">
      <li>개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명 등의 사항 에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.</li>
      <li>"{{name2}}"은 훈련생의 개인정보보호를 매우 중요시하며, "개인정보보호법" 상의 개인정보보호규정 및 행정안전부가 제정한 "개인정보보호지침"을 준수하고 있습니다. "{{name2}}"은 개인정보처리방침을 통하여 고객께서 제공하시는 개인정보가 어떠한 용도의 방식으로 이용되고 있으며 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.</li>
      <li>"{{name2}}"은 개인정보처리방침을 홈페이지 첫 화면에 공개함으로써 고객께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</li>
      <li>"{{name2}}"은 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보처리방침을 개정하는 경우 버전번호 등을 부여하여 개정 된 사항을 훈련생께서 쉽게 알아볼 수 있도록 하고 있습니다.</li>
    </ol>


    <h5 class="list-title">제 2 조 - 수집하는 개인정보의 항목 및 수집 방법</h5>
    <p class="sub-text">"{{name2}}"은 훈련생에게 본인확인, 결제 서비스, 훈련생등록 및 학점인정 신청 등 다양하고 편리한 서비스를 제공하기 위해 다음의 방법을 통해 개인정보를 수집하고 있습니다.</p>
    <ol class="list number">
      <li>수집하는 개인정보의 항목 및 방법 구분 수집 항목
          <table class="list-table">
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>수집 항목</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowspan="2">회원가입</th>
                <td>필수</td>
                <td>
                  이름, 휴대폰번호, 이메일 주소,<br>
                  비밀번호<small>(비밀번호는 8자 이상)</small><br>
                  서비스 이용약관에 동의, 개인정보 처리방침에 동의
                </td>
              </tr>
              <tr>
                <td>선택</td>
                <td>마케팅 목적의 개인정보 수집 및 이용에 동의, <br>광고성 정보 수신에 동의</td>
              </tr>
              <tr>
                <th>서비스 이용시<br> 수집되는 정보</th>
                <td>필수</td>
                <td>
                  서비스 이용기록, 접속로그<br>
                  쿠키, 접속 IP 정보 등<br>
                </td>
              </tr>
            </tbody>
          </table>
      </li>
    </ol>


    <h5 class="list-title">제 3 조 - 개인정보의 처리목적</h5>
    <p class="sub-text">
      “{{name2}}”은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
    </p>
    <ol class="list number">
      <li>
        <strong>홈페이지 회원 가입 및 관리</strong><br>
        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지,각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
      </li>
      <li>
        <strong>서비스 제공</strong><br>
        서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증 목적으로 개인정보를 처리합니다.
      </li>
      <li>
        <strong>고충처리</strong><br>
        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 <br>연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
      </li>
    </ol>

    <h5 class="list-title">제 4 조 - 개인정보의 수집 및 이용목적</h5>
    <ol class="list number">
      <li>"{{name2}}"은 서비스 제공을 위해 필요한 최소한의 범위 내에서 다음 각 항목과 같은 목적으로 개인정보 를 수집하고 있습니다.
          <table class="list-table">
              <thead>
                <tr>
                  <th colspan="2">개인정보수집</th>
                  <th>수집목적</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowspan="2">필수항목</th>
                  <td>이름, 휴대폰번호,<br>이메일 주소, 비밀번호</td>
                  <td>본인식별절차</td>
                </tr>
                <tr>
                  <td>이메일주소, 비밀번호</td>
                  <td>공지사항, <br>서비스정보의 제공</td>
                </tr>
              </tbody>
          </table>
      </li>
      <li>단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.</li>
    </ol>

    <h5 class="list-title">제 5 조 - 개인정보의 처리 및 보유기간</h5>
    <ul class="list hypen">
      <li>교육원은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</li>
      <li>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</li>
    </ul>

    <ol class="list number">
      <li>홈페이지 회원 가입 및 관리 : 사업자/단체/개인 홈페이지 탈퇴 시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
          <ol class="list number-circle">
            <li>관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지</li>
            <li>홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지</li>
          </ol>
      </li>
      <li>
        서비스 제공 : 서비스 공급완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
        <ol class="list number-circle">
          <li>
            「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
          </li>
          <li>
            「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
            <ul class="list hypen">
              <li>가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년</li>
              <li>컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>

    <h5 class="list-title">제 6 조 - 개인정보의 제3자 제공</h5>
    <ul class="list hypen">
      <li>“{{name2}}”은 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
      <li>“{{name2}}”은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</li>
    </ul>

    <ol class="list number">
      <li>
          직업능력개발훈련 모니터링에 관한 규정
          <ul class="list hypen">
            <li>개인정보를 제공받는 자 : 한국산업인력공단</li>
            <li>제공받는 자의 개인정보 이용목적 : 직업능력개발훈련 원격훈련 모니터링(문자발송 등)</li>
            <li>제공하는 개인정보 항목 : 이름, 휴대폰번호, 이메일 주소, 수강정보 등</li>
            <li>제공받는 자의 보유·이용기간 : 수집 후 3년, 자료분석 후 5년</li>
          </ul>
      </li>
      <!--li>SMS
        <ul class="list hypen">
          <li>개인정보를 제공받는 자 : 문자스토리</li>
          <li>제공받는 자의 개인정보 이용목적 : 회원가입 시 인증문자, 본인 인증문자</li>
          <li>제공하는 개인정보 항목 : 이름, 휴대폰번호</li>
          <li>제공받는 자의 보유·이용기간 : 서비스제공기간</li>
        </ul>
      </li -->
      <li>클라썸
        <ul class="list hypen">
          <li>개인정보를 제공받는 자 : 클라썸</li>
          <li>제공받는 자의 개인정보 이용목적 : 학습데이터 연동을 위한 본인 확인</li>
          <li>제공하는 개인정보 항목 : 이름, 이메일 주소</li>
          <li>제공받는 자의 보유·이용기간 : 참여자의 삭제 요청시 까지</li>
        </ul>
      </li>
    </ol>

    <h5 class="list-title">제 7 조 - 정보주체의 권리, 의무 및 행사방법</h5>

    <ol class="list number">
      <li>정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <ol class="list number-circle">
          <li>개인정보 열람요구</li>
          <li>오류 등이 있을 경우 정정 요구</li>
          <li>삭제요구</li>
          <li>처리정지 요구</li>
        </ol>
      </li>
      <li>제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 {{name2}}은 이에 대해 지체 없이 조치하겠습니다.</li>
      <li> 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 교육원은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</li>
      <li> 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
      <li> 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</li>
    </ol>

    <h5 class="list-title">제 8 조 - 처리하는 개인정보 항목</h5>
    <p class="sub-text">“{{name2}}“은 다음의 개인정보 항목을 처리하고 있습니다.</p>

    <ol class="list number">
      <li>홈페이지 회원 가입 및 관리
        <ul class="list hypen">
          <li>필수항목 : 이름, 휴대폰번호, 이메일 주소, 서비스 이용약관에 동의, 인정보 처리방침에 동의</li>
          <li>선택항목 : 마케팅 목적의 개인정보 수집 및 이용에 동의, 광고성 정보 수신에 동의</li>
        </ul>
      </li>
      <li>
        서비스 제공
        <ul class="list hypen">
          <li>필수항목 : 이름, 휴대폰번호, 이메일 주소,</li>
          <li>선택항목 : 없음</li>
        </ul>
      </li>
      <li>
        인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
        <ul class="list hypen">
          <li>IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등</li>
        </ul>
      </li>
    </ol>

    <h5 class="list-title">제 9 조 - 개인정보의 파기</h5>
    <ol class="list number">
      <li>“{{name2}}"은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
      <li>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</li>
      <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
    </ol>

    <ol class="list number-circle">
      <li>
        <strong>파기절차</strong>
        “{{name2}}"은 파기 사유가 발생한 개인정보를 선정하고, “{{name2}}의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
      </li>
      <li>
        <strong>파기방법</strong>
        “{{name2}}"은 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
      </li>
    </ol>

    <h5 class="list-title">제 10 조 - 개인정보의 안전성 확보조치</h5>
    <p class="sub-text">“{{name2}}"은 인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. </p>
    <ol class="list number">
      <li>관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</li>
      <li>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
      <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
    </ol>

    <h5 class="list-title">제 11 조 - 개인정보 보호책임자</h5>

    <ol class="list number">
      <li>
        “{{name2}}"은 인정보 처리에 관한 업무를 총괄해 서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
        <p class="sub-text mt-2 mb-1"><strong>▶ 개인정보 관리 책임자 및 담당자</strong></p>
        <ul class="list hypen">
          <li>성명 : 경승현</li>
          <li>소속 : 정보보호실</li>
          <li>전화번호 : 02-532-6500</li>
          <li>이메일 : ksho218@codelabit.co.kr</li>
        </ul>
        <!-- p class="sub-text mt-2 mb-1"><strong>▶ 개인정보 관리 담당자</strong></p>
        <ul class="list hypen">
          <li>성명 : 이휘경</li>
          <li>소속 : 웹서비스팀</li>
          <li>전화번호 : 02-532-650</li>
          <li>이메일 : dlgnlrud@ezenac.co.kr</li>
        </ul -->
      </li>
      <li>
        정보주체께서는 {{name2}}의 모든 개인정보 보호 관련 문의, 불만처리, 피해 구제 등에 관한 사항을 개인정보 총괄책임자 및 담당부서로 문의하실 수 있습니다. {{name2}}은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br>
      </li>
    </ol>

    <h5 class="list-title">제 12 조 - 권익침해 구제방법</h5>
    <p class="sub-text">
      정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>
      &lt;아래의 기관은 {{name2}}과는 별개의 기관으로서, 교육원의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.&gt;
    </p>
    <p class="sub-text mt-2 mb-1"><strong>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</strong></p>
    <ul class="list hypen">
      <li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
      <li>홈페이지 : privacy.kisa.or.kr</li>
      <li>전화 : (국번없이) 118</li>
      <li>주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</li>
    </ul>
    <p class="sub-text mt-2 mb-1"><strong>▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</strong></p>
    <ul class="list hypen">
      <li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
      <li>홈페이지 : privacy.kisa.or.kr</li>
      <li>전화 : (국번없이) 118</li>
      <li>주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</li>
    </ul>
    <p class="sub-text mt-2 mb-1"><strong>▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</strong></p>
    <p class="sub-text mt-2 mb-1"><strong>▶ 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)</strong></p>


    <h5 class="list-title">제 13 조 - 개인정보 처리방침 변경</h5>
    <ul class="list hypen">
      <li>이 개인정보 처리방침은 2023. 6. 28부터 적용됩니다.</li>
      <li>공고일자: 2023년 6월 28일</li>
      <li>시행일자: 2023년 6월 28일</li>
    </ul>
    <p class="sub-text mt-2">본 개인정보처리방침에서 규정되지 않은 사항은 관계법령의 범위 내에서 당사 이용약관을 우선적으로 적용합니다.</p>
  </div>
</template>

<style lang="scss" scoped>
ol {list-style:none; margin:0 0 1rem}
h1 {text-align:center; font-size:1.2rem;}
h4 {margin:0 0 .5rem; font-size:1rem; font-weight:400; word-wrap: break-word; word-break: keep-all;}
h2 {margin:0 0 1rem; font-size:1.1rem;}
.list-title{
  font-weight:bold;
  font-size:17px;
  padding:30px 0 10px 0;
}
.sub-text{
  font-size:15px;
  line-height:1.5;
  word-wrap: break-word;
  word-break: keep-all;
  > small {
    line-height:1.35;
  }
}
.list{
  padding:0;
  list-style: none;
  font-size:14px;
  &.hypen {
    > li {
      padding-left:15px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content:"-";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number {
    list-style: none;
    counter-reset: steps;
    > li {
      counter-increment: steps;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps)")";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number-circle {
    list-style: none;
    counter-reset: steps-circle;
    margin-top:10px;
    > li {
      counter-increment: steps-circle;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps-circle);
        position:absolute;
        left:-5px;
        top:1px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        height: 18px;
        width: 18px;
        line-height:16px;
        text-align: center;
        font-size:12px;
      }
    }
  }
}
.list-table{
  width:100%;
  margin:10px 0;
  border:1px solid #ddd;
  border-collapse: collapse;
  th {
    background-color:#fafafa;
    border:1px solid #ddd;
    padding:5px;
    text-align: center;
    font-size:14px;
  }
  td {
    border:1px solid #ddd;
    padding:5px;
  }
}


</style>

<script>
export default {
  data () {
    return {
      name: "(주)코드랩아카데미",
      siteUrl: "ezitlms.co.kr",
      name2: "코드랩"
    }
  },
}
</script>