<template>
  <w-popup title="비밀번호 변경" width="500" @close="$emit('close')">
    <v-form @submit.prevent="savePassword" class="pt-2">
      <v-text-field type="password" hide-details outlined label="기존 비밀번호" v-model.trim="formData.currentPassword" />
      <v-divider class="mt-2 mb-2" />
      <v-text-field class="mb-2" type="password" hide-details outlined label="새 비밀번호" v-model.trim="formData.newPassword" />
      <v-text-field type="password" hide-details outlined label="새 비밀번호 확인" v-model.trim="formData.newPasswordConfirm" />

      <v-divider class="mt-4 mb-4" />
      <v-btn large type="submit" color="black" dark block>비밀번호 변경</v-btn>
    </v-form>
  </w-popup>
</template>
<script>
import jwt from "@/plugins/jwt";
import userModel from '@/models/users.model'
import WPopup from "@/views/Components/WPopup";

export default {
  components: {WPopup},
  data () {
    return {
      formData: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm : ''
      }
    }
  },
  methods: {
    savePassword () {
      if(this.formData.currentPassword.length === 0) {
        this.$swal('비밀번호 변경 오류', '기존 비밀번호를 입력하세요' ,'error')
        return;
      }

      if(this.formData.newPassword.length === 0) {
        this.$swal('비밀번호 변경 오류', '새 비밀번호를 입력하세요' ,'error')
        return;
      }

      if(this.formData.newPasswordConfirm.length === 0) {
        this.$swal('비밀번호 변경 오류', '새 비밀번호 확인을 입력하세요' ,'error')
        return;
      }

      if(! /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.formData.newPassword))
      {
        this.$swal('비밀번호 변경 오류', '비밀번호는 8자 이상, 하나이상의 문자,숫자 및 특수문자를 사용하셔야 합니다' ,'error')
        return;
      }

      jwt.axios.post('/v1/users/password-change',{
        currentPassword: this.formData.currentPassword,
        newPassword: this.formData.newPassword
      }).then(() => {

        this.$swal({
          title: '비밀번호 변경완료',
          text: `새로운 비밀번호가 적용되었습니다.\n새 비밀번호로 다시 로그인해주세요`,
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: '확인'
        })
        .then(() => {
          userModel.signOut(true)
          this.$router.replace('/my')
        });

      })
    }
  },
  metaInfo() {
    return {
      title: '비밀번호 변경',
      meta: [
        {vmid:'og:title', property:'og:title', content: '비밀번호 변경'}
      ]
    }
  }
}
</script>