export default [
    {
        path: '/lectures/:lectureId',
        name:'lectureDetailView',
        component: () => import(/* webpackChunkName: "student.lectures.view" */ '@/views/Student/lectures/LectureInfo.vue'),
        children: [
            {path: '', name:'lectureDetailHome', component: () => import(/* webpackChunkName: "student.lectures.view.home" */ '@/views/Student/lectures/LectureInfoHome.vue')},
            {path: 'info', name:'lectureDetailBasic', component: () => import(/* webpackChunkName: "student.lectures.view.basic" */ '@/views/Student/lectures/LectureInfoBasic.vue')},
            {path: 'attendance', name:'lectureDetailAttendance', component: () => import(/* webpackChunkName: "student.lectures.view.attendance" */ '@/views/Student/lectures/LectureInfoAttendance.vue')},
            {path: 'qna', name:'lectureDetailQna', component: () => import(/* webpackChunkName: "student.lectures.view.qna" */ '@/views/Student/lectures/LectureInfoQna.vue')},
            {path: 'notice', name:'lectureDetailNotice', component: () => import(/* webpackChunkName: "student.lectures.view.Notice" */ '@/views/Student/lectures/LectureInfoNotice.vue')},
            {path: 'submit-list', name:'lectureSubmit', component: () => import(/* webpackChunkName: "student.lecture.submitList" */ '@/views/Student/lectures/LectureSubmitList.vue')},
            {path: 'feedback', name:'lectureSubmitFeedback', component: () => import(/* webpackChunkName: "student.lecture.submitFeedback" */ '@/views/Student/lectures/LectureSubmit.vue')},
            {path: 'students', name:'lectureDetailStudents', component: () => import(/* webpackChunkName: "student.lectures.view.students" */ '@/views/Student/lectures/LectureInfoStudents.vue')},
            {path: 'code-review', name:'lectureCodeReview', component: () => import(/* webpackChunkName: "student.lectures.view.codereview" */ '@/views/Student/CodeReview/CodeReviewList.vue')},
            {path: 'code-review/new', name:'lectureCodeReviewAdd', component: () => import(/* webpackChunkName: "student.lectures.codereview.add" */ '@/views/Student/CodeReview/CodeReviewForm.vue')},
            {path: 'code-review/:id', name:'lectureCodeReviewView', component: () => import(/* webpackChunkName: "student.lectures.codereview.view" */ '@/views/Student/CodeReview/CodeReviewView.vue')},
            {path: 'code-review/:id/edit', name:'lectureCodeReviewEdit', component: () => import(/* webpackChunkName: "student.lectures.codereview.edit" */ '@/views/Student/CodeReview/CodeReviewForm.vue')},
            {path: 'coding-test', name:'lectureCodingTest', component: () => import(/* webpackChunkName: "student.lectures.codingTest.list" */ '@/views/Student/CodeReview/CodingTest.vue')},
            {path: 'coding-test/:id', name:'lectureCodingTestView', component: () => import(/* webpackChunkName: "student.lectures.codingTest.view" */ '@/views/Student/CodeReview/CodingTestView.vue')},
            {path: 'training-status', name:'trainingStatus', component: () => import(/* webpackChunkName: "student.lecture.trainingStatus" */ '@/views/Student/lectures/TrainingStatus.vue')},
        ]
    },
    {path:'/lectures/:lectureId/chat', name:'lectureChat', component: () => import(/* webpackChunkName: "student.lectures.chat" */ '@/views/Student/lectures/LectureChat.vue'), meta: {useLayout: false}}
]