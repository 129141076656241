<template>
  <v-app class="admin-theme" :class="{minify:ui.mini, 'no-layout':useLayout===false}" style="background: #e2e2e5;">
    <side-menu
        v-if="useLayout"
        :mini="ui.mini"
        @menuToggle="onMenuToggle"
    />
    <v-main class="other">
      <div class="contents-wrap">
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from "@/views/Admin/SideMenu.vue";

export default {
  name: 'App',
  components: {SideMenu},
  data () {
    return {
      dialog: false,
      ui: {
        height:0,
        mini: false,
      },
    }
  },
  created () {
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  mounted() {
    this.onWindowResize()
    document.addEventListener('resize', this.onWindowResize)

    const menuMinify = window.localStorage.getItem('menu-minify')
    this.ui.mini = menuMinify === true || menuMinify === 'true'

    let customVH = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', customVH + 'px')
    window.addEventListener('resize', () => {
      let customVH = window.innerHeight * 0.01
      document.documentElement.style.setProperty(
          '--vh',
          customVH + 'px',
      )
    })

    this.$Progress.finish()
  },
  methods: {
    onMenuToggle(value) {
      this.ui.mini = value;
      window.localStorage.setItem('menu-minify', this.ui.mini);
    },
    onWindowResize() {
      this.ui.height = window.innerHeight
    },
  },
  sockets: {
    disconnect () {
      this.$socket.emit('signIn', {
        nickname: this.loginUser.nickname,
        userId: this.loginUser.id * 1
      })
    }
  }
};
</script>
