import vue from 'vue'
import jwt from '@/plugins/jwt'
import usersModel from "@/models/users.model";
import router from '@/router'

const exportObject = {}

exportObject.onKakaoGetProfile = (res) => {
    let data = {};

    data.email = typeof res.kakao_account !== 'undefined' && typeof res.kakao_account.email !== 'undefined' && res.kakao_account.email ? res.kakao_account.email : ''
    data.social_kakao = res.id
    data.nickname = typeof res.properties !== 'undefined' && typeof res.properties.nickname && res.properties.nickname ? res.properties.nickname : ''
    data.photo = typeof res.properties !== 'undefined' && typeof res.properties.profile_image && res.properties.profile_image ? res.properties.profile_image : ''

    return data;
}

exportObject.onKakakSignIn = ( $kakao, redirect) => {
    $kakao.Auth.login({
        success: () => {
            window.Kakao.API.request({
                url: '/v2/user/me',
                success: res => {
                    const data = exportObject.onKakaoGetProfile(res)
                    exportObject.onSocialSignIn('kakao', data, redirect)
                },
                fail: error => {
                    vue.swal('Error', error, 'error')
                }
            })
        },
        fail: function(err) {
            vue.swal('Error', err, 'error')
        }
    })
}

exportObject.onSocialSignIn = async (provider, data, redirect) => {
    await jwt
        .axios
        .post('/v1/users/social/' + provider,data)
        .then(async (res) => {

            if(typeof redirect !== 'undefined' && redirect === true) {
                location.reload()
            }
            else if(typeof redirect !== 'undefined' && redirect)
            {
                await usersModel.loginAfterProcess(res)
                router.replace(redirect)
            }
            else {
                await usersModel.loginAfterProcess(res)
                router.replace('/')
            }

        })
}

export default exportObject