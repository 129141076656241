<template>
  <v-app :class="{minify:ui.mini, 'no-layout':useLayout===false}">
    <side-menu
        v-if="useLayout"
        :mini="ui.mini"
        @menuToggle="onMenuToggle"
    />

    <v-main style="background-color:#fff" :class="{'other': (useLayout === false)}">
      <div class="contents-wrap">
        <router-view/>
      </div>
      <div class="contents-footer">
        <div class="inner">
          <div class="d-flex align-center">
            <v-btn elevation="2" @click="dialog=true; openAgreement('privacy')" dark>개인정보처리방침</v-btn>
            <v-btn elevation="2" outlined @click="dialog=true; openAgreement('site')" class="ml-2">서비스이용약관</v-btn>
            <v-btn elevation="2" outlined @click="dialog=true; openAgreement('marketing')" class="ml-2">마케팅정보동의</v-btn>
          </div>
        </div>
      </div>
    </v-main>
    <template>
      <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2 d-sr-only" v-html="ui.agreementTitle"></v-card-title>

            <v-card-text style="min-height:300px;">
              <privacy-view class="pt-10" v-if="ui.agreementOpened&&ui.agreementType==='privacy'" />
              <site-agree-view class="pt-10" v-if="ui.agreementOpened&&ui.agreementType==='site'" />
              <marketing-view class="pt-10" v-if="ui.agreementOpened&&(ui.agreementType==='marketing' || ui.agreementType==='receive')" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <div class="chatbot-container" :class="{'other': (useLayout === false)}" @click="openChatGPT">
      <div id="chatbot">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <div id="chatbot-corner"></div>
      <div id="antenna">
        <div id="beam"></div>
        <div id="beam-pulsar"></div>
      </div>
    </div>
    <vue-progress-bar />
  </v-app>
</template>
<script>
import SideMenu from "@/views/SideMenu.vue";
import MarketingView from "@/views/Student/agreement/MarketingView";
import SiteAgreeView from "@/views/Student/agreement/SiteAgreeView";
import PrivacyView from "@/views/Student/agreement/PrivacyView";

export default {
  name: 'App',
  components: {SideMenu, MarketingView, SiteAgreeView, PrivacyView},
  data () {
    return {
      dialog: false,
      ui: {
        height:0,
        mini: false,
        agreementOpened: false,
        agreementType: '',
        agreementTitle: '',
      },
      formData: {
        agreeSite: false,
        agreePrivacy: false,
        agreeMarketing: false,
        agreeReceive: false
      },
    }
  },
  created () {
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  mounted() {
    this.onWindowResize()
    document.addEventListener('resize', this.onWindowResize)

    const menuMinify = window.localStorage.getItem('menu-minify')
    this.ui.mini = menuMinify === true || menuMinify === 'true'

    let customVH = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', customVH + 'px')
    window.addEventListener('resize', () => {
      let customVH = window.innerHeight * 0.01
      document.documentElement.style.setProperty(
          '--vh',
          customVH + 'px',
      )
    })

    this.$Progress.finish()
  },
  methods: {
    onMenuToggle(value) {
      this.ui.mini = value;
      window.localStorage.setItem('menu-minify', this.ui.mini);
    },
    onWindowResize() {
      this.ui.height = window.innerHeight
    },
    onDialogSubmit() {
      if(this.ui.agreementType === 'site') {
        this.formData.agreeSite = true
      }
      else if(this.ui.agreementType === 'privacy') {
        this.formData.agreePrivacy = true;
      }
      else if (this.ui.agreementType === 'marketing') {
        this.formData.agreeMarketing = true;
      }
      else if (this.ui.agreementType === 'receive') {
        this.formData.agreeReceive = true;
      }

      this.dialog = false
    },
    openAgreement(type) {
      this.ui.agreementOpened = true
      this.ui.agreementType = type
      if(type === 'site') {
        this.ui.agreementTitle = '서비스 이용약관'
      }
      else if (type === 'privacy') {
        this.ui.agreementTitle = '개인정보 처리방침'
      }
      else if (type === 'marketing') {
        this.ui.agreementTitle = '마케팅 목정의 수신정보 동의'
      }
      else if (type === 'receive') {
        this.ui.agreementTitle = '광고성 수신정보 동의'
      }
    },
  },
  sockets: {
    disconnect () {
      this.$socket.emit('signIn', {
        nickname: this.loginUser.nickname,
        userId: this.loginUser.id * 1
      })
    }
  }
};
</script>