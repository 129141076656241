<template>
  <div class="--form-row" :class="addClass">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.--form-row {
  display:flex;
  width:100%;
}
</style>

<script>
export default {
  props: {
    alignItems: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    addClass () {
      let rtr = "";

      if(this.alignItems === 'center') {
        rtr += " align-center";
      }

      return rtr;
    }
  }
}
</script>