import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ko from 'vuetify/lib/locale/ko'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#28937f',
            },
            dark: {
                primary: '#000'
            }
        }
    },
    lang: {
        locales: {ko},
        current: 'ko'
    }
});
