import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import '@/_scss/style.scss'

import '@/libraries/formatter.js'

// Vue Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#0c0e12',
  cancelButtonColor: '#D9D9D9'
};

Vue.use(VueSweetalert2, options)

// Vue ProgressBar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#0c0e12',
  failedColor: '#28937f',
  height: '2px'
})

// mixins
import mixins from "@/mixins";
Vue.mixin(mixins);

import '@/plugins/wheeparam'

const generateUniqueKey = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

let sessionID = localStorage.getItem('sessionID');

// 중복로그인 방지를 위한 처리
if (!sessionID) {
  sessionID = generateUniqueKey(); // 고유키 생성 메서드
  localStorage.setItem('sessionID', sessionID);
}

// Socket.IO 접속을 위한 처리
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client';

Vue.use(new VueSocketIO({
  debug:false,
  connection: SocketIO(  process.env.NODE_ENV === 'production' ? config.prodApiHost : config.devApiHost, {
    cors: { origin: '*',  credentials: true },
    query: {
      nickname: userModel.isLogin() ? store.getters["authorize/userInfo"].nickname : "손님",
      userId: userModel.isLogin() ? store.getters["authorize/userInfo"].id * 1 : 0,
      photo: userModel.isLogin() ? store.getters['authorize/userInfo'].photo : '',
      sessionID
    }
  }),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
}))

// DatePicker Global
import Datepicker from 'vuejs-datepicker'
import { ko } from 'vuejs-datepicker/dist/locale'

Vue.component('w-datepicker', {
  extends: Datepicker,
  props: {
    language: {
      type: Object,
      default: function () {
        return ko
      },
    },
    format: {
      type: String,
      default :'yyyy-MM-dd'
    },
    inputClass: {
      type: String,
      default : 'w-input w-input-datepicker'
    }
  },
})

// 새로고침등을 했을때 로그인이 되어있는지 여부를 다시 체크
import userModel from '@/models/users.model'
import config from "@/config";
if(userModel.isLogin())
{
  store.commit('authorize/setLogin', true)
  userModel.getMyInfo()
}


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
