<template>
  <div class="--box" :class="{'no-padding':noPadding}"><slot /></div>
</template>

<style lang="scss" scoped>
.--box {
  box-shadow:rgba(76, 78, 100, 0.08) 0 2px 9px -2px,
             rgba(76, 78, 100, 0.05) 0px 4px 7px 1px,
             rgba(76, 78, 100, 0.03) 0px 2px 6px 4px;

  border-radius:10px;
  padding:16px;
  background-color:#fff;
  &.no-padding {
    padding:0;
  }
}
</style>

<script>
export default {
  name: 'WBox',
  props: {
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>