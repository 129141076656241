/**
 * 사용자 관련 Routers
 */
export default [
    {path: '/sign-in', name:'SignIn',component: () => import(/* webpackChunkName: "student.users.signIn" */ '@/views/Student/users/SignIn.vue'), meta: {requiredLogin:false, useLayout:false} },
    {path: '/sign-up', name:'SignUp',component: () => import(/* webpackChunkName: "student.users.signUp" */ '@/views/Student/users/SignUp.vue'), meta: {requiredLogin:false, useLayout:false} },
    {path: '/find/id', name:'findId',component: () => import(/* webpackChunkName: "student.find.id" */ '@/views/Student/users/FindId.vue'), meta: {requiredLogin:false, useLayout:false} },
    {path: '/find/pass', name:'findPass',component: () => import(/* webpackChunkName: "student.find.pass" */ '@/views/Student/users/FindPass.vue'), meta: {requiredLogin:false, useLayout:false} },
    {path: '/classroom', name:'ClassRoom',component: () => import(/* webpackChunkName: "student.users.classroom" */ '@/views/Student/users/ClassRoom')},
    {path: '/my/withdraw', name: 'my-info-withdarw', component: () => import(/* webpackChunkName: "student.users.my.withdraw" */ '@/views/Student/users/MyWithdraw'), meta: {requiredLogin: true}  },
    {path: '/oauth/authorize', name:'ClassumOauthorize', component: () => import(/* webpackChunkName: "student.oauth.authorize" */ '@/views/Student/oauth/Authorize'), meta: {requiredLogin: true, useLayout:false}  },
    {path: '/social/sign-in', name:'SocialLogin', component: () => import(/* webpackChunkName: "student.social-login" */ '@/views/Student/oauth/SocialLogin'), meta: {requiredLogin: false, useLayout:false}  },
    {path: '/social/sign-up', name:'SocialSignUp', component: () => import(/* webpackChunkName: "student.social-sign-up" */ '@/views/Student/oauth/SocialSignUp'), meta: {requiredLogin: false, useLayout:false}  },
    {path: '/social/link', name:'SocialLink', component: () => import(/* webpackChunkName: "student.social-sign-up" */ '@/views/Student/oauth/SocialLink'), meta: {requiredLogin: false, useLayout:false}  },
]