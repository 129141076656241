import vue from 'vue'
import WPopup from "@/views/Components/WPopup.vue";
import WBox from "@/views/Components/WBox.vue";
import AFormGroup from "@/views/Components/Forms/AFormGroup.vue";
import AFormLabel from "@/views/Components/Forms/AFormLabel.vue";
import AControls from "@/views/Components/Forms/AControls.vue";

vue.component('w-popup', WPopup);
vue.component('w-box', WBox);
vue.component('a-form-group', AFormGroup);
vue.component('a-form-label', AFormLabel);
vue.component('a-controls', AControls);

export default {}